
import {defineComponent, onMounted} from "vue";
import Loading             from '@/components/Loading.vue';
import {useBannerSizeList} from "../../logic/banner-size/banner-size-list";

export default defineComponent({
  name      : "BannerSizeList",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sizes, sizesReady, loadSizes, deleteSize} = useBannerSizeList();

    onMounted(() => loadSizes());

    return {sizes, sizesReady, deleteSize}
  },
})
