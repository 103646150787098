import ApiClient                      from "../client/ApiClient";
import {AxiosInstance, AxiosResponse} from "axios";
import {Api}                          from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import UrlLoc                         from "./UrlLoc";

export default class ApiListFetcher<T> {
    private _client: AxiosInstance;

    constructor(private urlLoc: UrlLoc) {
        this._client = ApiClient.init();
    }

    public index(params: {page: number} | null = null): Promise<LaravelIndexResponse<T>> {
        return this._client.get(this.getIndexUrl(), {params: params ?? {}}).then((response: AxiosResponse<LaravelIndexResponse<T>>) => {
            return response.data;
        })
    }


    public delete(id: number) {
        return this._client.delete(this.getDeleteUrl(id))
    }


    protected getIndexUrl(): string {
        return this.urlLoc.indexUrl;
    }


    protected getDeleteUrl(id: number): string {
        return this.urlLoc.deleteUrl + '/' + id;
    }
}