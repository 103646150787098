import {ref}          from 'vue';
import ApiListFetcher from "../../services/api/ApiListFetcher";
import {Api}          from "../../types/api/v1/electrification";
import LaravelIndexResponse = Api.V1.LaravelIndexResponse;
import BannerSizeUrlLoc from "../../services/api/banner-size/BannerSizeUrlLoc";
import BannerSize = Api.V1.BannerSize;

export const useBannerSizeList = () => {
    const sizes      = ref<Array<BannerSize>>([]);
    const sizesReady = ref<boolean>(true);

    const fetcher = new ApiListFetcher<BannerSize>(new BannerSizeUrlLoc());

    // @ts-ignore
    const setSizes = (value: Array<BannerSize>) => sizes.value = value;

    const loadSizes = () => {
        fetcher.index().then((data: LaravelIndexResponse<BannerSize>) => {
            setSizes(data.data);
            sizesReady.value = true;
        });
    }

    const deleteSize = (id: number) => {
        fetcher.delete(id).then(() => {
            loadSizes();
        })
    }

    return {sizes, sizesReady, setSizes, loadSizes, deleteSize};
};