<template>
  <h2>Размеры баннеров</h2>
  <Loading v-if="!sizesReady"></Loading>
  <router-link :to="{name: 'sizes-form'}" class="uk-button uk-button-primary">Добавить размер</router-link>
  <table class="uk-table uk-table-divider" v-if="sizesReady">
    <caption>Учетные записи</caption>
    <thead>
    <tr>
      <th>ID</th>
      <th>Название</th>
      <th>Размер</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="size in sizes">
      <td>{{ size.id }}</td>
      <td>{{ size.name }}</td>
      <td>{{ size.size }}</td>
      <td>
        <button class="uk-button uk-button-text" @click="deleteSize(size.id)">&times;</button>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script lang="ts">
import {defineComponent, onMounted} from "vue";
import Loading             from '@/components/Loading.vue';
import {useBannerSizeList} from "../../logic/banner-size/banner-size-list";

export default defineComponent({
  name      : "BannerSizeList",
  components: {
    Loading: Loading,
  },
  setup() {
    const {sizes, sizesReady, loadSizes, deleteSize} = useBannerSizeList();

    onMounted(() => loadSizes());

    return {sizes, sizesReady, deleteSize}
  },
})
</script>
