import axios, {AxiosInstance} from "axios";

export default class ApiClient {
    static init(): AxiosInstance {

        return axios.create({
            baseURL: process.env.VUE_APP_API_URL + '/api/v1/',
            headers: {
                common: {
                    Accept: 'application/json'
                }
            }
        });
    }
}
